import { Container } from "@/components/Shared/Container/Container";
import { Heading } from "@/components/Shared/Heading /Heading";
import { Box } from "@chakra-ui/layout";
import React from "react";
import Layout from "../components/Shared/Layout/Layout";
import { SEO } from "../components/Shared/SEO/SEO";

const GdorPage = (props: any) => {
  return (
    <Layout>
      <SEO description="This is an index page" />
      <Container py={200} textAlign="center">
        <Box>
          <Heading fontSize="9xl">404</Heading>
          <Heading fontSize="2xl" color="secondary">
            Zdá sa že ste zablúdili...
          </Heading>
        </Box>
      </Container>
    </Layout>
  );
};

export default GdorPage;
